export const fantasilandiaStateSchema = {
    "fantasilandia_home_title": "Escanea tu cédula de identidad para guardar tus pertenencias",
    "fantasilandia_home_text_1": "Si no tienes tu cédula de identidad, presiona aquí",
    "fantasilandia_home_btn_1": "No tengo cédula de identidad",
    "fantasilandia_delivery_with_document_title": "Guarda tus pertenencias y cierra la puerta",
    "fantasilandia_delivery_with_document_btn_1": "Finalizar",
    "fantasilandia_pickup_with_document_title": "Retira tus pertenencias y cierra la puerta",
    "fantasilandia_pickup_with_document_btn_1": "Finalizar",
    "fantasilandia_delivery_pickup_without_document_document_title": "Ingresa tu rut sin puntos ni guion",
    "fantasilandia_delivery_pickup_without_document_document_btn_1": "Cancelar",
    "fantasilandia_delivery_pickup_without_document_document_btn_2": "Continuar",
    "fantasilandia_delivery_without_document_pin_title": "Crea su contraseña de 4 dígitos",
    "fantasilandia_delivery_without_document_pin_btn_1": "Cancelar",
    "fantasilandia_delivery_without_document_pin_btn_2": "Continuar",
    "fantasilandia_delivery_without_document_title": "Guarda tus pertenencias y cierra la puerta",
    "fantasilandia_delivery_without_document_btn_1": "Finalizar",
    "fantasilandia_pickup_without_document_pin_title": "Ingresa tu contraseña de 4 dígitos",
    "fantasilandia_pickup_without_document_pin_btn_1": "Cancelar",
    "fantasilandia_pickup_without_document_pin_btn_2": "Continuar",
    "fantasilandia_pickup_without_document_title": "Retira tus pertenencias y cierra la puerta",
    "fantasilandia_pickup_without_document_btn_1": "Finalizar",
  };
  
  export const SchemaSections: any = {
    home: ["fantasilandia_home_title", "fantasilandia_home_btn_1"],
    delivery_with_document: ["fantasilandia_delivery_with_document_title", "fantasilandia_delivery_with_document_btn_1"],
    pickup_with_document: ["fantasilandia_pickup_with_document_title", "fantasilandia_pickup_with_document_btn_1"],
    delivery_pickup_without_document_document: ["fantasilandia_delivery_pickup_without_document_document_title", "fantasilandia_delivery_pickup_without_document_document_btn_1", "fantasilandia_delivery_pickup_without_document_document_btn_2"],
    delivery_without_document_pin: ["fantasilandia_delivery_without_document_pin_title", "fantasilandia_delivery_without_document_pin_btn_1", "fantasilandia_delivery_without_document_pin_btn_2"],
    delivery_without_document: ["fantasilandia_delivery_without_document_title", "fantasilandia_delivery_without_document_btn_1"],
    pickup_without_document_pin: ["fantasilandia_pickup_without_document_pin_title", "fantasilandia_pickup_without_document_pin_btn_1", "fantasilandia_pickup_without_document_pin_btn_2"],
    pickup_without_document: ["fantasilandia_pickup_without_document_title", "fantasilandia_pickup_without_document_btn_1"],
  };
  