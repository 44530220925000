import { useEffect, useState } from "react";
import { Button } from "rsuite";

const FantasilandiaTextForm = ({
  onConfirm,
  module,
}: {
  onConfirm: (data: any) => void;
  module: any;
}) => {
  const [state, setState] = useState({
    save_config_as_template: false,
    validations: {},
  });

  useEffect(() => {
    setState({
      ...module,
    });
  }, [module]);

  const _handleValidations = () => {
    onConfirm({
      ...state,
      save_config_as_template: false,
      validations: {},
    });
  };
  return (
    <div className="container py-3 px-5">
      <div className="w-100 text-end driver-save-configs">
        <Button appearance="primary" onClick={_handleValidations}>
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default FantasilandiaTextForm;
